<template>
  <section class="login_wrap bg-white">
    <div class="row no-gutters min-vh-100">
      <div
        class="col-lg-5 d-none d-lg-block bg-primary login_slider_bg"
        style="background-image: url('../../assets/images/login/login_bg.png')"
      >
        <a class="p-3 d-inline-block login_logo" href="">
          <img class="w-80" src="../../assets/images/logo.svg" alt="" />
        </a>
        <div class="login_slider_box">
          <div class="text-center pb-2">
            <div class="login_slider_box_img">
              <img
                class="d-inline-block mw-100"
                src="../../assets/images/login/login_img.png"
                alt=""
              />
            </div>
          </div>
          <div class="text-white text-center">
            <h1 class="text-white">Protect Your Nest Egg and Enjoy Your Retirement</h1>
            <p>
              We're here to help. The Heyday team has worked with hundreds of
              retirees to help minimize financial risk and maximize their
              income.
            </p>
          </div>
        </div>
      </div>

      <div class="col-lg-7 d-flex align-items-center justify-content-center">
        <div class="login_box">
          <div class="login_box_form">
            <a class="login_tab_logo d-inline-block d-lg-none mb-4" href="">
              <img src="/images/HeyDay-Logo.svg" alt="" />
            </a>
            <h6 class="text-primary mb-3 font-size-sm">
              Welcome! <span class="font-size-lg">👋</span>
            </h6>
            <h3 class="text-dark mb-3">{{ registration ? 'Register' : 'Sign in' }}  to Heyday Retirement</h3>
            <form
              action=""
              @submit.prevent="registration ? userRegistration() : userLogin()"
            >
              <div class="form-group" v-if="registration">
                <label for="full_name" class="text-dark">Full Name</label>
                <input
                  type="text"
                  name="text"
                  id="full_name"
                  placeholder="John Eliot"
                  class="form-control"
                  v-model="user.name"
                />
              </div>
              <div class="form-group">
                <label for="email_address" class="text-dark"
                  >Email Address</label
                >
                <input
                  type="text"
                  name="email"
                  id="email_address"
                  placeholder="info@heydayretirement.com"
                  class="form-control"
                  v-model="user.email"
                />
              </div>
              <div class="form-group">
                <label for="email_address" class="text-dark">Password</label>
                <input
                  type="password"
                  name="password"
                  id="email_address"
                  class="form-control"
                  v-model="user.password"
                />
              </div>
              <div class="forget">
                <router-link to="/forgot-password" class="font-size-sm font-weight-bolder">
                  Reset Password?
                </router-link>
              </div>

              <button
                type="submit"
                class="btn btn-primary btn-lg btn-block mt-4 mb-2"
                :disabled="user.password == '' && user.email == ''"
              >
                {{ registration ? 'Register' : 'Sign In' }} <i class="fas fa-arrow-right pl-1"></i>
              </button>
              <a
                href="javascript:void(0);"
                @click="registration = !registration"
              >
                {{
                  registration
                    ? "Already have Account ?"
                    : "Don't have Account ?"
                }}
              </a>
            </form>
            <div class="full_or border-bottom border-gray-200 mb-4 text-center">
              <span class="bg-white px-2 text-muted position-relative">OR</span>
            </div>
            <div class="mb-4">
              <button
                class="btn btn-lg btn-light btn-block text-muted shadow"
                @click="socialLogin"
              >
                <img
                  src="../../assets/images/login/google-icon.svg"
                  alt="SIGN-IN WITH GOOGLE"
                />
                SIGN-IN WITH GOOGLE
              </button>
            </div>
            <div class="mb-4">
			            <a href="javascript:void(0);" class="btn btn-lg btn-light btn-block text-muted shadow"  @click="socialLoginFb">
			            	<img src="../../assets/images/login/facebook-icon.svg" alt="SIGN-IN WITH FACEBOOK">
			            	SIGN-IN WITH FACEBOOK
			            </a>
			        </div>
          </div>
          <div class="login_box_copyright font-size-sm text-secondary">
            Copyright {{ new Date().getFullYear() }}
            <a
              href="https://www.heydayretirement.com/"
              target="_blank"
              class="text-secondary"
              >Heydayretirement.com</a
            >
            All rights reserved.
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { firebase } from "@/utils/firebase.js";

export default {
  name: "LoginEmail",
  components: {},
  data() {
    return {
      registration: false,
      user: {
        name: "",
        email: "",
        password: "",
      },
    };
  },
  mounted() {
    console.log(process.env);
  },
  methods: {
    resetForm() {
      this.user.name = "";
      this.user.email = "";
      this.user.password = "";
    },
    userLogin() {
      // this.$store.dispatch('login', {
      // 	email: this.user.email,
      // 	password: this.user.password
      // })
      
      firebase
        .auth()
        .signInWithEmailAndPassword(this.user.email, this.user.password)
        .then((result) => {
          this.$store.dispatch("setUserLoginData", result.user);
          this.$router.replace({ name: 'dashboard'});
        })
        .catch((error) => {
          alert(error.message);
        });
    },
    userRegistration() {
      firebase
        .auth()
        .createUserWithEmailAndPassword(this.user.email, this.user.password)
        .then((res) => {
          
          console.log('res');
          console.log(res);
          
          let localUser = {...res.user};
          res.user
          .updateProfile({
            displayName: this.user.name,
          })
          .then((data) => {
            localUser.name = this.user.name;
            this.$store.dispatch("setUserLoginData", localUser);
            this.$router.replace({ name: 'dashboard'});
          });

        })
        .catch((error) => {
          if(error.code  == 'auth/email-already-in-use') {
            this.$swal({
              title: '<strong>Email already exists!</strong>',
              icon: 'info',
              html: 'This email account is already in use please <a href="/login"><b>login</b></a> or <b>create a new account</b>.',
              showCloseButton: false,
              showCancelButton: true,
              focusConfirm: false,
              confirmButtonText: 'Login',
            }).then((result) => {
              if (result.isConfirmed) {
                this.$router.replace({ name: 'Login'});
              } 
              else if (result.isDenied) {
                this.resetForm();
              }
            })
          } else {
            alert(error.message);
          }
        
        });
    },
    socialLogin() {
      const provider = new firebase.auth.GoogleAuthProvider();
      firebase
        .auth()
        .signInWithPopup(provider)
        .then((result) => {
          this.$store.dispatch("setUserLoginData", result.user);
          this.$router.replace({ name: 'dashboard'});
        })
        .catch((err) => {
          alert("Oops. " + err.message);
        });
    },
    socialLoginFb() {
        const provider = new firebase.auth.FacebookAuthProvider();
        firebase.auth().signInWithPopup(provider).then((result) => {
          this.$store.dispatch('setUserLoginData', result.user);
          this.$router.replace({ name: 'dashboard'});
        }).catch((err) => {
          alert('Oops. ' + err.message)
        });
    }
  },
};
</script>
